@media only screen 
and (min-width: 992px) {
    .section2ContentReact1 {
        font-size: 18px;
        margin-bottom: 100px;
    }
    // history
    .section3ContentReact1 {
        font-size: 18px;
    }

    // UA BI
    .section5TitleReact1 {
        font-size: 40px;
    }
    .section5ContentReact1 {
        font-size: 18px;
    }

}

@media only screen 
and (max-width: 991px) {
    .section1TitleReact1 {
        font-size: 2.4rem;
    }
    .section2TitleReact1 {
        font-size: 2rem;
    }
    .section2ContentReact1 {
        font-size: 1.4rem;
    }
    // history
    .section3TitleReact1 {
        font-size: 2rem;
    }
    .section3ContentReact1 {
        font-size: 1.2rem;
    }

    // patent, certificate, intellectual property
    .section4TitleReact1 {
        font-size: 2rem;
    }
    .section4ContentReact1 {
        font-size: 1.2rem;
    }
    .dateHeader {
        width: 45px;
        min-width: 45px;
    }

    // UA BI
    .section5TitleReact1 {
        font-size: 2.4rem;
    }
    .section5TitleReact2 {
        font-size: 2rem;
    }
    .section5ContentReact1 {
        font-size: 1.1rem;
    }
    // Ehtical AI
    .section6TitleReact1 {
        font-size: 2.4rem;
    }
    .section6ContentReact1 {
        font-size: 1.1rem;
    }
}